
import { Options, Vue } from "vue-class-component";

@Options({
    data() {
        return {
            showToolbar: false
        }
    }
})
export default class App extends Vue {
    showToolbar!: boolean;

    onInstall() {
        window.open("https://itunes.apple.com/app/id1641940021?ref=launch");
    }

    onDocs() {
        window.open("https://ouroboros.bluepill.life/docs/index.html");
    }

    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll(event: Event) {
        this.showToolbar = window.scrollY > (window.innerHeight / 2);
    }
}
